export enum ThemeSetting {
    System = "System",
    Dark = "Dark",
    Light = "Light"
}

export function applyTheme(theme: ThemeSetting) {
    let themeClass = ''
    switch (theme) {
        case ThemeSetting.Dark:
            themeClass = 'dark'
            break
        case ThemeSetting.Light:
            themeClass = 'light'
            break
    }
    document.body.className = themeClass
}

interface Settings {
    themeSetting: ThemeSetting
}

const SETTINGS_KEY = "settings"

class SettingsManager {
    private static _instance: SettingsManager | undefined

    private config: Settings

    private constructor() {
        const storedConfig = localStorage.getItem(SETTINGS_KEY)
        this.config = storedConfig ? JSON.parse(storedConfig) : {
            themeSetting: ThemeSetting.System
        }
    }

    static getInstance() {
        if (!SettingsManager._instance) {
            SettingsManager._instance = new SettingsManager()
        }
        return SettingsManager._instance
    }

    get theme() {
        return this.config.themeSetting || ThemeSetting.System
    }

    set theme(newSetting: ThemeSetting) {
        this.config.themeSetting = newSetting
        this.updateSettings()
        applyTheme(this.config.themeSetting)
    }

    private updateSettings() {
        localStorage.setItem(SETTINGS_KEY, JSON.stringify(this.config))
    }
}

const settingsManager = SettingsManager.getInstance()

export default settingsManager
