const elapsedTimeFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 4
})

const formatPegaTimestamp = (date: Date) => {
  const parts = Intl.DateTimeFormat('utc', {
    timeZone: 'UTC', hourCycle: 'h24',
    day: '2-digit', month: '2-digit', year: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
    fractionalSecondDigits: 3
  }).formatToParts(date)
  let [year, month, day, hour, minute, second, fraction] = ''
  for (let part of parts) {
    switch (part.type) {
      case 'year':
        year = part.value
        break
      case 'month':
        month = part.value
        break
      case 'day':
        day = part.value
        break
      case 'hour':
        hour = part.value
        break
      case 'minute':
        minute = part.value
        break
      case 'second':
        second = part.value
        break
      case 'fractionalSecond':
        fraction = part.value
        break
    }
  }

  return `${year}${month}${day}T${hour}${minute}${second}.${fraction} GMT`
}

export default class TraceEvent {
  key?: number
  traceLogKey?: number
  activePALStat?: string
  activityName?: string
  activityNumber?: number
  aDPLoadPageName?: string
  correlationId?: string
  dateTime?: Date
  elapsed?: number
  eventKey?: string
  eventName?: string
  eventType?: string
  firstInput?: string
  insKey?: string //
  interaction?: number
  interactionBytes?: number
  interactionQueryData?: string
  interactionQueryParam?: string
  javaStackTrace?: string //
  keyName?: string
  lastStep?: string
  localVariables: Map<string, string> = new Map()
  name?: string //
  namedPages: Map<String, string> = new Map()
  nodeId?: string
  nodeName?: string
  parameterPageContent?: Map<string, string> = new Map()
  parameterPageName?: string
  primaryPageClass?: string
  primaryPageContent?: string
  primaryPageName?: string
  requestorId?: string
  ruleNumber?: number //
  rulesetName?: string
  rulesetVersion?: string
  sequence?: number
  stepMethod?: string
  stepNumber?: string
  stepStatus?: string
  threadName?: string
  timestamp?: string //
  whenStatus?: string
  workPool?: string

  get sequenceDisplay() {
    return (this.sequence || 0) + 1
  }

  get nameDisplay() {
    switch (this.eventType) {
      case 'Interaction':
        return this.interactionQueryParam
      case 'ADP Load':
        const nameParts = this.name?.split(' ')
        if (nameParts && nameParts.length > 4) {
          const d = document.createElement('div')
          d.innerHTML = nameParts[4]
          d.innerHTML = d.innerText
          return `${nameParts[1]} ${d.textContent}`
        }

        return ''
      default:
        return this.keyName
    }
  }

  get rulesetDisplay() {
    switch (this.eventType) {
      case 'Access Denied':
      case 'DB Cache':
      case 'DB Query':
      case 'Interaction':
        return ''
      default:
        return `${this.rulesetName} ${this.rulesetVersion}`
    }
  }

  get statusDisplay() {
    switch (this.eventType) {
      case 'Interaction':
        return `${Intl.NumberFormat().format(this.interactionBytes || 0)}(b)`
      case 'When End':
        return this.whenStatus
      default:
        return this.stepStatus
    }
  }

  get elapsedDisplay() {
    if (this.elapsed) {
      return elapsedTimeFormatter.format(this.elapsed / 1000)
    }
    return ''
  }

  get dateTimeDisplay() {
    if (this.dateTime) {
      return `${Intl.DateTimeFormat(navigator.language, { dateStyle: 'medium', timeStyle: 'long' }).format(this.dateTime)} (${formatPegaTimestamp(this.dateTime)})`
    }
    return ''
  }

  get stepMethodDisplay() {
    switch (this.eventType) {
      case 'ADP Load':
        return this.eventName
      default:
        return this.stepMethod
    }
  }

  get primaryPageNameDisplay() {
    switch (this.eventType) {
      case 'ADP Load':
        return this.aDPLoadPageName
      default:
        return this.primaryPageName
    }
  }

  get eventNameDisplay() {
    switch (this.eventType) {
      case 'ADP Load':
        return this.eventType
      default:
        return this.eventName
    }
  }

  public serialize(): any {
    const obj: any = {}
    const t: any = this
    Object.keys(t).forEach((key) => {
      if (t[key] !== undefined) {
        obj[key] = t[key]
      }
    })

    return obj
  }
}
