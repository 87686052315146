import { useEffect, useState } from "react"
import { Cell, flexRender, getCoreRowModel, Row, useReactTable } from "@tanstack/react-table"
import { createColumnHelper } from "@tanstack/table-core"
import TraceEvent from "../tracer-data/TraceEvent"
import styles from './TraceDataTable.module.css'
import { launchTraceEventDetailView } from "./TraceEventDetailView"
import { launchTraceEventPageView } from "./TraceEventPageView"
import TraceLogDataAccess from "../tracer-data/TraceLogDataAccess"

const columnHelper = createColumnHelper<TraceEvent>()
const columns = [
    columnHelper.accessor('sequenceDisplay', {
        id: 'sequence',
        header: () => 'Line',
        size: 20,
        minSize: 20
    }),
    columnHelper.accessor('threadName', {
        header: () => 'Thread',
        size: 75
    }),
    columnHelper.accessor('interaction', {
        header: () => 'Int',
        size: 15,
        minSize: 15
    }),
    columnHelper.accessor('activityNumber', {
        header: () => 'Rule#',
        size: 25,
        minSize: 15
    }),
    columnHelper.accessor('stepMethodDisplay', {
        id: 'stepMethod',
        header: () => 'Step Method',
        size: 100
    }),
    columnHelper.accessor('primaryPageNameDisplay', {
        id: 'primaryPageName',
        header: () => 'Step Page',
        size: 100
    }),
    columnHelper.accessor('stepNumber', {
        id: 'step',
        header: () => 'Step',
        size: 20,
        minSize: 15
    }),
    columnHelper.accessor('statusDisplay', {
        id: 'status',
        header: () => 'Status',
        size: 75
    }),
    columnHelper.accessor('eventNameDisplay', {
        id: 'eventName',
        header: () => 'Event Type',
        size: 75
    }),
    columnHelper.accessor('elapsedDisplay', {
        id: 'elapsed',
        header: () => 'Elapsed',
        size: 35
    }),
    columnHelper.accessor('nameDisplay', {
        id: 'name',
        header: () => 'Name',
        size: 125
    }),
    columnHelper.accessor('rulesetDisplay', {
        id: 'ruleset',
        header: () => 'Ruleset',
        size: 100
    })
]

interface TraceDataTableProps {
    traceLogDataAccessor: TraceLogDataAccess
    traceLogKey: number
}

export default function TraceDataTableFull(props: TraceDataTableProps) {
    const [data, setData] = useState<TraceEvent[]>([])
    useEffect(() => {
        props.traceLogDataAccessor.getTraceEvents(props.traceLogKey).then(setData)
    }, [props.traceLogDataAccessor, props.traceLogKey])

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    })

    const getRowClassName = (row: Row<TraceEvent>) => {
        const classNames = []
        const event = row.original
        switch (event.eventType) {
            case 'Interaction':
            case 'Stream Rules':
                classNames.push(styles.interactionEvent)
                break
            case 'ADP Load':
                classNames.push(styles.adpEvent)
                break
        }

        classNames.push(row.index % 2 === 0 ? styles.odd : styles.even)

        return classNames.join(' ')
    }

    const getCellClassName = (cell: Cell<TraceEvent, any>) => {
        if (cell.column.id === 'step') {
            return styles.stepNumber
        }
        if (cell.column.id === 'eventName' && cell.getValue() === 'Access Denied') {
            return styles.accessDenied
        }
    }

    const cellClickHandler = (cell: Cell<TraceEvent, any>) => {
        if (cell.column.id === 'primaryPageName' && cell.row.original.primaryPageName) {
            // display primary page data
            launchTraceEventPageView('primary', cell.row.original.key)
        } else {
            launchTraceEventDetailView(cell.row.original.key)
        }
    }

    return (
        <div className={styles.container}>
            <table>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )
                                    }
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id} className={getRowClassName(row)}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id} className={getCellClassName(cell)} title={cell.getValue() as any} onClick={() => cellClickHandler(cell)}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    {table.getFooterGroups().map(footerGroup => (
                        <tr key={footerGroup.id}>
                            {footerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.footer,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </div>
    )
}
