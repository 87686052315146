import { ReactElement } from "react";
import { launchPopup } from "./TraceEventDetailView";
import styles from "./TraceEventPageView.module.css"

interface TraceEventPageViewProps {
    pageName?: string
    pageContent?: string
}

export default function TraceEventPageView(props: TraceEventPageViewProps) {
    (window as any).data = props.pageContent
    const sourceData = (new DOMParser()).parseFromString(props.pageContent || '', 'application/xml')
    const pageData = sourceData.querySelector('pagedata')

    return <div className={styles.eventDetail}>
        <header><h1>Properties on Page TraceEvent [{props.pageName}]</h1></header>
        <main>
            <div>
                <h2>Properties on Page {props.pageName}</h2>
                {buildTableFromPage(pageData)}
            </div>
        </main>
    </div>
}

export function traceEventPageViewLink(viewType: 'primary' | 'namedPage', eventKey?: string | number, pageName?: string): string {
    return process.env.PUBLIC_URL + `/index.html?event=${eventKey}&viewType=${viewType}${pageName ? `&pageName=${pageName}` : ''}`
}

export function launchTraceEventPageView(viewType: 'primary' | 'namedPage', eventKey: string | number | undefined, pageName?: string) {
    launchPopup(traceEventPageViewLink(viewType, eventKey, pageName))
}

function buildTableFromPage(input: Element | null): ReactElement | undefined {
    if (input) {
        const propertyRows = [];
        const elements = input.querySelectorAll(':scope > *')
        for (let el of elements) {
            const nodeName = el.nodeName
            if (el.children.length === 0) {
                // single valued property
                propertyRows.push(
                    <tr key={el.nodeName}>
                        <th>{el.nodeName}</th>
                        <td>{el.textContent}</td>
                    </tr>
                )
            } else {
                const pages = el.querySelectorAll(':scope > rowdata')
                if (pages.length > 0) {
                    // repeating list
                    for (let pEl of pages) {
                        const index = pEl.getAttribute('REPEATINGINDEX')
                        const label = `${nodeName}(${index})`
                        propertyRows.push(
                            <tr key={label}>
                                <th>{label}</th>
                                <td className={styles.collapsePadding}>
                                    {buildTableFromPage(pEl)}
                                </td>
                            </tr>
                        )
                    }
                } else {
                    // page object
                    propertyRows.push(
                        <tr key={el.nodeName}>
                            <th>{el.nodeName}</th>
                            <td className={styles.collapsePadding}>
                                {buildTableFromPage(el)}
                            </td>
                        </tr>
                    )
                }
            }
        }

        if (propertyRows.length > 0) {
            return <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {propertyRows}
                </tbody>
            </table>
        }
    }
}
