import TraceEvent from "../tracer-data/TraceEvent"
import styles from "./TraceEventDetailView.module.css"
import { launchTraceEventPageView, traceEventPageViewLink } from "./TraceEventPageView"
import { launchTraceEventPropertiesView, traceEventPropertiesViewLink } from "./TraceEventPropertiesView"

interface TraceEventDetailViewProps {
    event?: TraceEvent
}

export default function TraceEventDetailView(props: TraceEventDetailViewProps) {
    const event = props.event
    if (!event) {
        return <div>no event</div>
    }

    const openParameterPage = (ev: React.MouseEvent) => {
        ev.preventDefault()
        launchTraceEventPropertiesView('parameters', event.key)
        return false
    }
    const openPrimaryPage = (ev: React.MouseEvent) => {
        ev.preventDefault()
        launchTraceEventPageView('primary', event.key)
        return false
    }
    const openVariablesPage = (ev: React.MouseEvent) => {
        ev.preventDefault()
        launchTraceEventPropertiesView('variables', event.key)
        return false
    }
    const openNamedPage = (pageName: string) => (ev: React.MouseEvent) => {
        ev.preventDefault()
        launchTraceEventPageView('namedPage', event.key, pageName)
        return false
    }

    let displayDetails
    switch (event.eventType) {
        case 'Interaction':
            displayDetails = <div>
                <h2>Interaction</h2>
                <table>
                    <tbody>
                        <tr><th>Bytes</th><td>{Intl.NumberFormat().format(event.interactionBytes || 0)}</td></tr>
                        <tr><th>Query Params</th><td>{event.interactionQueryParam}</td></tr>
                        <tr><th>Query Data</th><td>{event.interactionQueryData}</td></tr>
                    </tbody>
                </table>
            </div>
            break
        default:
            displayDetails = <div>
                <h2>Standard</h2>
                <table>
                    <tbody>
                        <tr><th>Activity Name</th><td>{event.activityName}</td></tr>
                        <tr><th>Activity Number</th><td>{event.activityNumber}</td></tr>
                        <tr><th>Parameter Page Name</th><td><a href={traceEventPropertiesViewLink('parameters', event.key)} onClick={openParameterPage}>{event.parameterPageName}</a></td></tr>
                        <tr><th>Primary Page Class</th><td>{event.primaryPageClass}</td></tr>
                        <tr><th>Primary Page Name</th><td><a href={traceEventPageViewLink('primary', event.key)} onClick={openPrimaryPage}>{event.primaryPageName}</a></td></tr>
                        {event.localVariables.size === 0 || <tr><th>Local Variables</th><td><a href={traceEventPropertiesViewLink('variables', event.key)} onClick={openVariablesPage}>View Variables</a></td></tr>}
                        {event.stepMethod && <tr><th>Step Method</th><td>{event.stepMethod}</td></tr>}
                        {event.stepNumber !== undefined && <tr><th>Step Number</th><td>{event.stepNumber}</td></tr>}
                        {event.stepStatus && <tr><th>Step Status</th><td>{event.stepStatus}</td></tr>}
                        {event.stepStatus && <tr><th>Step Info</th><td>{event.stepStatus}</td></tr>}
                        {event.namedPages.size === 0 || <tr>
                            <th>Additional Named Pages</th>
                            <td>
                                <ul className={styles.pageList}>
                                    {Array.from(event.namedPages.keys(), (key) => {
                                        return <li key={key as string}><a href={traceEventPageViewLink('namedPage', event.key)} onClick={openNamedPage(key as string)}>{key}</a></li>
                                    })}
                                </ul>
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
    }

    return <div className={styles.eventDetail}>
        <header><h1>Properties for TraceEvent {(event.sequence || 0) + 1}</h1></header>
        <main>
            <div>
                <h2>Header</h2>
                <table>
                    <tbody>
                        <tr><th>Sequence</th><td>{event.sequence}</td></tr>
                        <tr><th>Interaction</th><td>{event.interaction}</td></tr>
                        <tr><th>Timestamp</th><td>{event.dateTimeDisplay}</td></tr>
                        <tr><th>Event Type</th><td>{event.eventType}</td></tr>
                        <tr><th>Event Name</th><td>{event.eventName}</td></tr>
                        {event.eventKey && <tr><th>Event Key</th><td>{event.eventKey}</td></tr>}
                        <tr><th>Thread Name</th><td>{event.threadName}</td></tr>
                        <tr><th>Requestor ID</th><td>{event.requestorId}</td></tr>
                        <tr><th>Correlation ID</th><td>{event.correlationId}</td></tr>
                        <tr><th>Node ID</th><td>{event.nodeId}</td></tr>
                        <tr><th>Work Pool</th><td>{event.workPool}</td></tr>
                        <tr><th>Last Step</th><td>{event.lastStep}</td></tr>
                        <tr><th>Input</th><td>{event.firstInput}</td></tr>
                        <tr><th>Ruleset Name</th><td>{event.rulesetName}</td></tr>
                        <tr><th>Ruleset Version</th><td>{event.rulesetVersion}</td></tr>
                    </tbody>
                </table>
            </div>
            {displayDetails}
            {event.javaStackTrace && <div>
                <h2>Stack trace</h2>
                <div>
                    {event.javaStackTrace}
                </div>
            </div>}
        </main>
    </div>
}

export function launchTraceEventDetailView(eventKey: string | number | undefined) {
    launchPopup(process.env.PUBLIC_URL + `/index.html?event=${eventKey}`)
}

export function launchPopup(url: string) {
    window.open(
        url,
        "_blank",
        "width=1024,height=700"
    );
}
