import { useCallback, useEffect, useState } from "react";
import TraceLog, { TraceLogStatus } from "../tracer-data/TraceLog";
import TraceLogDataAccess from "../tracer-data/TraceLogDataAccess"
import TraceDataTable from "./TraceDataTable"
import TraceDataTableAGGrid from "./TraceDataTableAGGrid";
import TraceDataTableFull from "./TraceDataTableFull";
import TraceDataTableNative from "./TraceDataTableNative";
import TraceDataTablePaged from "./TraceDataTablePaged";
import TraceDataTableVirtual from "./TraceDataTableVirtual";
import styles from "./TraceDataViewer.module.css"
import TraceDataFlameGraph from "./TraceDataFlameGraph";

enum ViewOptions {
  Table = "Table",
  Flame = "Flame"
}

enum TableViewOptions {
  Default = "Default",
  Virtual = "Virtual",
  Full = "Full",
  Native = "Native",
  AGGrid = "AGGrid",
  Paged = "Paged",
}

interface TraceDataViewerProps {
  traceLogDataAccessor: TraceLogDataAccess
}

export default function TraceDataViewer(props: TraceDataViewerProps) {
  const [selectedTraceLog, setSelectedTraceLog] = useState<number | undefined>()
  const [traceLogs, setTraceLogs] = useState<TraceLog[]>([])
  const [currentView, setCurrentView] = useState(ViewOptions.Table)

  const updateSelectedTraceLog = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const newKey = Number.parseInt(event.target.value)
    setSelectedTraceLog(newKey)
  }, [])

  const updateView = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = ViewOptions[event.target.value as keyof typeof ViewOptions]
    console.log(`onchange ${event.target.value} ${value}`)
    setCurrentView(value)
  }, [])

  useEffect(() => {
    const retrieveList = async () => {
      let traceLogs = await props.traceLogDataAccessor.getTraceLogs()
      traceLogs = traceLogs.filter(l => l.key && l.status === TraceLogStatus.Completed)
      setTraceLogs(traceLogs)
      if (traceLogs.length > 0) {
        setSelectedTraceLog(traceLogs[0].key)
      }
    }
    retrieveList()
  }, [props.traceLogDataAccessor])

  const [currentTableView, setCurrentTableView] = useState(TableViewOptions.Default)

  const updateTableView = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = TableViewOptions[event.target.value as keyof typeof TableViewOptions]
    console.log(`onchange ${event.target.value} ${value}`)
    setCurrentTableView(value)
  }, [])

  let child
  if (selectedTraceLog !== undefined) {
    switch (currentView) {
      case ViewOptions.Table:
        switch (currentTableView) {
          case TableViewOptions.Virtual:
            child = <TraceDataTableVirtual traceLogDataAccessor={props.traceLogDataAccessor} traceLogKey={selectedTraceLog} />
            break
          case TableViewOptions.Full:
            child = <TraceDataTableFull traceLogDataAccessor={props.traceLogDataAccessor} traceLogKey={selectedTraceLog} />
            break
          case TableViewOptions.Native:
            child = <TraceDataTableNative traceLogDataAccessor={props.traceLogDataAccessor} traceLogKey={selectedTraceLog} />
            break
          case TableViewOptions.AGGrid:
            child = <TraceDataTableAGGrid traceLogDataAccessor={props.traceLogDataAccessor} traceLogKey={selectedTraceLog} />
            break
          case TableViewOptions.Paged:
            child = <TraceDataTablePaged traceLogDataAccessor={props.traceLogDataAccessor} traceLogKey={selectedTraceLog} />
            break
          case TableViewOptions.Default:
          default:
            child = <TraceDataTable traceLogDataAccessor={props.traceLogDataAccessor} traceLogKey={selectedTraceLog} />
        }
        break
      case ViewOptions.Flame:
        child = <TraceDataFlameGraph traceLogDataAccessor={props.traceLogDataAccessor} traceLogKey={selectedTraceLog} />
        break
    }
  } else {
    child = <div>Select Trace Log</div>
  }

  return (
    <div className={styles.traceViewer}>
      <div className={styles.toolbar}>
        <div>
          <label htmlFor="traceLogSelector">Trace Log: </label>
          <select name="tracelog" id="traceLogSelector" title="Trace Log Selection" value={selectedTraceLog} onChange={updateSelectedTraceLog} >
            {traceLogs.map((traceLog) =>
              <option value={traceLog.key} key={traceLog.key}>{traceLog.name ? traceLog.name : traceLog.key}</option>
            )}
          </select>
        </div>
        <div>
          <label htmlFor="viewSetting">View: </label>
          <select name="view" id="viewSetting" title="Current View" value={currentView} onChange={updateView} >
            <option value={ViewOptions.Table}>Table</option>
            <option value={ViewOptions.Flame}>Flame</option>
          </select>
        </div>
        <div hidden={currentView !== ViewOptions.Table}>
          <label htmlFor="tableViewSetting">Table Type: </label>
          <select name="view" id="tableViewSetting" title="Current Table View" value={currentTableView} onChange={updateTableView} >
            <option value={TableViewOptions.Default}>Default</option>
            <option value={TableViewOptions.Virtual}>Virtual</option>
            <option value={TableViewOptions.Full}>Full</option>
            <option value={TableViewOptions.Native}>Native</option>
            <option value={TableViewOptions.AGGrid}>AG Grid</option>
            <option value={TableViewOptions.Paged}>Paged</option>
          </select>
        </div>
      </div>
      {child}
    </div>
  )
}
