import React, { useState } from "react";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import styles from "./TraceDataUploader.module.css"
import traceDataUploadHandler from "../workers/traceDataUploadHandler";

interface TraceDataUploaderProps {
  setDataLoadedState: (status: boolean) => void
}

const ACCEPT_FILE_TYPES = {
  'application/zip': ['.zip'],
  'application/xml': ['.xml'],
  'text/xml': ['.xml'],
}

export default function TraceDataUploader(props: TraceDataUploaderProps) {
  const [fileLoading, setFileLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const onDropAccepted = async function (files: File[], _event: DropEvent) {
    setFileLoading(true)

    const result = await traceDataUploadHandler(files[0])

    if (result === '') {
      setFileLoading(false)
      props.setDataLoadedState(true)
    } else {
      setFileLoading(false)
      setErrorMessage(result)
    }
  }

  const onDropRejected = async function (fileRejections: FileRejection[], _event: DropEvent) {
    let error = "invalid file rejected"
    fileRejections.forEach((val) => {
      val.errors.forEach((err) => {
        if (err.code === 'file-invalid-type') {
          error = error + ' - file type invalid'
        }
      })
    })

    setErrorMessage(error)
  }

  if (!window.Worker) {
    return (
      <section className={styles.container}>
        <h2>This site currently requires Web Workers to load tracer export files, consider upgrading your browser.</h2>
      </section>
    )
  }

  if (fileLoading) {
    return (
      <section className={styles.container}>
        <h2>File loading in progress…</h2>
        <p>Please be patient this make take a moment.</p>
      </section>
    )
  }

  return (
    <Dropzone onDropAccepted={onDropAccepted} onDropRejected={onDropRejected} multiple={false} maxFiles={1} accept={ACCEPT_FILE_TYPES} >
      {({ getRootProps, getInputProps }) => (
        <section className={styles.container} >
          {errorMessage && <p className={styles.error}>Error: {errorMessage}</p>}
          <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop a tracer export archive zip or xml file here, or click to select a file</p>
          </div>
        </section>
      )}
    </Dropzone>
  )
}
