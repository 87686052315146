import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Main from './components/Main';
import SettingsModal from './components/SettingsModal';

import TraceLogDataAccess from './tracer-data/TraceLogDataAccess';

interface AppProps {
  initialDataLoaded: boolean
  traceLogDataAccessor: TraceLogDataAccess
}

export default function App(props: AppProps) {
  const [dataLoaded, setDataLoaded] = useState(props.initialDataLoaded)
  const [isSettingsOpen, setSettingsOpen] = useState(false)

  const clearDataLoaded = async function () {
    await props.traceLogDataAccessor.clearAll()
    setDataLoaded(false)
  }

  return (
    <div className="App">
      <SettingsModal isOpen={isSettingsOpen} closeSettings={() => setSettingsOpen(false)} />
      <Header dataLoaded={dataLoaded} clearDataLoaded={clearDataLoaded} openSettings={() => setSettingsOpen(true)} />
      <Main traceLogDataAccessor={props.traceLogDataAccessor} dataLoaded={dataLoaded} setDataLoaded={setDataLoaded} />
    </div>
  );
}
