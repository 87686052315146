import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { Cell, flexRender, getCoreRowModel, Row, useReactTable, getPaginationRowModel } from "@tanstack/react-table"
import { createColumnHelper } from "@tanstack/table-core"
import TraceEvent from "../tracer-data/TraceEvent"
import styles from './TraceDataTablePaged.module.css'
import { useVirtualizer } from "@tanstack/react-virtual"
import { launchTraceEventDetailView } from "./TraceEventDetailView"
import { launchTraceEventPageView } from "./TraceEventPageView"
import TraceLogDataAccess from "../tracer-data/TraceLogDataAccess"

const columnHelper = createColumnHelper<TraceEvent>()
const columns = [
    columnHelper.accessor('sequenceDisplay', {
        id: 'sequence',
        header: () => 'Line',
        size: 20,
        minSize: 20
    }),
    columnHelper.accessor('threadName', {
        header: () => 'Thread',
        size: 75
    }),
    columnHelper.accessor('interaction', {
        header: () => 'Int',
        size: 15,
        minSize: 15
    }),
    columnHelper.accessor('activityNumber', {
        header: () => 'Rule#',
        size: 25,
        minSize: 15
    }),
    columnHelper.accessor('stepMethodDisplay', {
        id: 'stepMethod',
        header: () => 'Step Method',
        size: 100
    }),
    columnHelper.accessor('primaryPageNameDisplay', {
        id: 'primaryPageName',
        header: () => 'Step Page',
        size: 100
    }),
    columnHelper.accessor('stepNumber', {
        id: 'step',
        header: () => 'Step',
        size: 20,
        minSize: 15
    }),
    columnHelper.accessor('statusDisplay', {
        id: 'status',
        header: () => 'Status',
        size: 75
    }),
    columnHelper.accessor('eventNameDisplay', {
        id: 'eventName',
        header: () => 'Event Type',
        size: 75
    }),
    columnHelper.accessor('elapsedDisplay', {
        id: 'elapsed',
        header: () => 'Elapsed',
        size: 35
    }),
    columnHelper.accessor('nameDisplay', {
        id: 'name',
        header: () => 'Name',
        size: 125
    }),
    columnHelper.accessor('rulesetDisplay', {
        id: 'ruleset',
        header: () => 'Ruleset',
        size: 100
    })
]

interface TraceDataTableProps {
    traceLogDataAccessor: TraceLogDataAccess
    traceLogKey: number
}

export default function TraceDataTable(props: TraceDataTableProps) {
    const [data, setData] = useState<TraceEvent[]>([])
    useEffect(() => {
        props.traceLogDataAccessor.getTraceEvents(props.traceLogKey).then(setData)
    }, [props.traceLogDataAccessor, props.traceLogKey])
    const tableContainerRef = useRef<HTMLDivElement>(null)

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize: 50
            }
        }
    })

    useLayoutEffect(() => {
        const recalculatePageSize = () => {
            if (tableContainerRef.current) {
                const headEl = tableContainerRef.current.querySelector('thead')
                const headHeight = headEl ? headEl.offsetHeight : 23
                const bodyHeight = tableContainerRef.current.offsetHeight - headHeight
                table.setPageSize(Math.floor(bodyHeight / headHeight) - 2)
            }
        }
        recalculatePageSize()
        window.addEventListener('resize', recalculatePageSize)

        return () => {
            window.removeEventListener('resize', recalculatePageSize)
        }
    }, [tableContainerRef, table])

    const { rows } = table.getRowModel()
    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableContainerRef.current,
        count: rows.length,
        estimateSize: () => 23,
        overscan: 5,
        //        keyExtractor: (i: number) => rows[i].original.key as number
    })

    const virtualRowTotal = rowVirtualizer.getTotalSize()
    const virtualRows = rowVirtualizer.getVirtualItems()
    const paddingTop =
        virtualRows.length > 0
            ? virtualRows?.[0]?.start || 0
            : 0
    const paddingBottom =
        virtualRows.length > 0
            ? virtualRowTotal - (virtualRows?.[virtualRows.length - 1]?.end || 0)
            : 0

    const getRowClassName = (row: Row<TraceEvent>) => {
        const classNames = []
        const event = row.original
        switch (event.eventType) {
            case 'Interaction':
            case 'Stream Rules':
                classNames.push(styles.interactionEvent)
                break
            case 'ADP Load':
                classNames.push(styles.adpEvent)
                break
        }

        classNames.push(row.index % 2 === 0 ? styles.odd : styles.even)

        return classNames.join(' ')
    }

    const getCellClassName = (cell: Cell<TraceEvent, any>) => {
        if (cell.column.id === 'step') {
            return styles.stepNumber
        }
        if (cell.column.id === 'eventName' && cell.getValue() === 'Access Denied') {
            return styles.accessDenied
        }
    }

    const cellClickHandler = (cell: Cell<TraceEvent, any>) => {
        if (cell.column.id === 'primaryPageName' && cell.row.original.primaryPageName) {
            // display primary page data
            launchTraceEventPageView('primary', cell.row.original.key)
        } else {
            launchTraceEventDetailView(cell.row.original.key)
        }
    }

    return (
        <div className={styles.outerContainer}>
            <div ref={tableContainerRef} className={styles.container}>
                <table>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )
                                        }
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {paddingTop > 0 && (
                            <tr>
                                <td style={{ height: `${paddingTop}px` }} />
                            </tr>
                        )}
                        {virtualRows.map(virtualRow => {
                            const row = rows[virtualRow.index]
                            return (
                                <tr key={row.id} className={getRowClassName(row)}>
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id} className={getCellClassName(cell)} title={cell.getValue() as any} onClick={() => cellClickHandler(cell)}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                        {paddingBottom > 0 && (
                            <tr>
                                <td style={{ height: `${paddingBottom}px` }}></td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        {table.getFooterGroups().map(footerGroup => (
                            <tr key={footerGroup.id}>
                                {footerGroup.headers.map(header => (
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.footer,
                                                header.getContext()
                                            )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                </table>
            </div>
            <div className={styles.paging}>
                <div>
                    <button type="button"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<<'}
                    </button>
                    <button type="button"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<'}
                    </button>
                    <button type="button"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>'}
                    </button>
                    <button type="button"
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>>'}
                    </button>
                </div>
                <div>
                    <span>Page&nbsp;</span>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of{' '}
                        {table.getPageCount()}
                    </strong>
                </div>
                {/* <select title="Page Size"
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select> */}
            </div>
        </div>
    )
}
