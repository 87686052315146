export default async function traceDataUploadHandler(file:File): Promise<string> {
    return new Promise((resolve, _reject) => {
      const worker = new Worker(new URL("./traceDataUpload/worker.ts", import.meta.url))
      worker.addEventListener('message', (event: MessageEvent<string>) => {
        resolve(event.data)
      })

      worker.postMessage(file)
    })
}
