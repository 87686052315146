import { useCallback, useEffect, useMemo, useState } from "react"
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import styles from './TraceDataTableAGGrid.module.css'
import TraceEvent from "../tracer-data/TraceEvent"
import { launchTraceEventDetailView } from "./TraceEventDetailView"
import { launchTraceEventPageView } from "./TraceEventPageView"
import TraceLogDataAccess from "../tracer-data/TraceLogDataAccess"
import { CellClassParams, CellClassRules, CellClickedEvent, GetRowIdParams, GridReadyEvent, RowClassParams } from "ag-grid-community";

const eventNameClassRules: CellClassRules = {}
eventNameClassRules[styles.accessDenied] = (params: CellClassParams<TraceEvent>) => params.value === 'Access Denied'

interface TraceDataTableProps {
    traceLogDataAccessor: TraceLogDataAccess
    traceLogKey: number
}

export default function TraceDataTableAGGrid(props: TraceDataTableProps) {
    const [data, setData] = useState<TraceEvent[]>([])
    useEffect(() => {
        props.traceLogDataAccessor.getTraceEvents(props.traceLogKey).then(setData)
    }, [props.traceLogDataAccessor, props.traceLogKey])

    const columns = useMemo(() => [
        { colId: 'sequence', headerName: 'Line', field: 'sequenceDisplay', minWidth: 20, maxWidth: 45, cellClass: styles.firstCell },
        { colId: 'threadName', headerName: 'Thread', field: 'threadName', minWidth: 75, flex: 1 },
        { colId: 'interaction', headerName: 'Int', field: 'interaction', minWidth: 15, maxWidth: 25 },
        { colId: 'activityNmber', headerName: 'Rule#', field: 'activityNumber', minWidth: 25, maxWidth: 50 },
        { colId: 'stepMethod', headerName: 'Step Method', field: 'stepMethodDisplay', minWidth: 100, flex: 2 },
        { colId: 'primaryPageName', headerName: 'Step Page', field: 'primaryPageNameDisplay', minWidth: 100, flex: 3 },
        { colId: 'stepNumber', headerName: 'Step', field: 'stepNumber', minWidth: 25, maxWidth: 35, cellClass: styles.stepNumber },
        { colId: 'status', headerName: 'Status', field: 'statusDisplay', minWidth: 75, maxWidth: 150 },
        { colId: 'eventName', headerName: 'Event Type', field: 'eventNameDisplay', minWidth: 75, cellClassRules: eventNameClassRules },
        { colId: 'elapsed', headerName: 'Elapsed', field: 'elapsedDisplay', minWidth: 35, maxWidth: 75 },
        { colId: 'name', headerName: 'Name', field: 'nameDisplay', minWidth: 125, flex: 4 },
        { colId: 'ruleset', headerName: 'Ruleset', field: 'rulesetDisplay', minWidth: 100, flex: 2, cellClass: styles.lastCell },
    ], [])

    const getRowClass = useCallback((params: RowClassParams<TraceEvent>) => {
        const classNames = []
        const event = params.data
        if (event) {
            switch (event.eventType) {
                case 'Interaction':
                case 'Stream Rules':
                    classNames.push(styles.interactionEvent)
                    break
                case 'ADP Load':
                    classNames.push(styles.adpEvent)
                    break
            }
        }

        return classNames
    }, [])

    const cellClickHandler = useCallback((event: CellClickedEvent<TraceEvent>) => {
        const eventKey = event.data?.key
        if (event.colDef.colId === 'primaryPageName' && event.value !== '') {
            launchTraceEventPageView('primary', eventKey)
        } else {
            launchTraceEventDetailView(eventKey)
        }
    }, [])

    const getRowId = useCallback((param: GetRowIdParams<TraceEvent>) => `${param.data.key}`, [])

    const onGridReady = useCallback((event: GridReadyEvent<TraceEvent>) => {
        event.api.sizeColumnsToFit()
    }, [])

    return (
        <div className={styles.container}>
            <AgGridReact<TraceEvent>
                rowData={data}
                columnDefs={columns}
                onCellClicked={cellClickHandler}
                getRowId={getRowId}
                getRowClass={getRowClass}
                onGridReady={onGridReady}
                enableBrowserTooltips={true}
                suppressHorizontalScroll={true}
            >
            </AgGridReact>
        </div >
    )
}
