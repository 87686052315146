import { useEffect, useState } from "react"
import TraceEvent from "../tracer-data/TraceEvent"
import styles from './TraceDataTable.module.css'
import { launchTraceEventDetailView } from "./TraceEventDetailView"
import { launchTraceEventPageView } from "./TraceEventPageView"
import TraceLogDataAccess from "../tracer-data/TraceLogDataAccess"

interface TraceDataTableProps {
    traceLogDataAccessor: TraceLogDataAccess
    traceLogKey: number
}

export default function TraceDataTableNative(props: TraceDataTableProps) {
    const [data, setData] = useState<TraceEvent[]>([])
    useEffect(() => {
        props.traceLogDataAccessor.getTraceEvents(props.traceLogKey).then(setData)
    }, [props.traceLogDataAccessor, props.traceLogKey])

    const getRowClassName = (index: number) => {
        const classNames = []
        const event = data[index]
        switch (event.eventType) {
            case 'Interaction':
            case 'Stream Rules':
                classNames.push(styles.interactionEvent)
                break
            case 'ADP Load':
                classNames.push(styles.adpEvent)
                break
        }

        classNames.push(index % 2 === 0 ? styles.odd : styles.even)

        return classNames.join(' ')
    }

    const rowClickHandler = (event: React.MouseEvent<HTMLTableRowElement>) => {
        const target = event.target as HTMLElement
        const eventKey = target.dataset.eventkey
        const column = target.dataset.column

        if (column === 'primaryPageName' && target.innerText !== '') {
            launchTraceEventPageView('primary', eventKey)
        } else {
            launchTraceEventDetailView(eventKey)
        }
    }

    return (
        <div className={styles.container}>
            <table>
                <thead>
                    <tr>
                        <th style={{ width: 20 }}>Line</th>
                        <th style={{ width: 75 }}>Thread</th>
                        <th style={{ width: 15 }}>Int</th>
                        <th style={{ width: 25 }}>Rule#</th>
                        <th style={{ width: 100 }}>Step Method</th>
                        <th style={{ width: 100 }}>Step Page</th>
                        <th style={{ width: 20 }}>Step</th>
                        <th style={{ width: 75 }}>Status</th>
                        <th style={{ width: 75 }}>Event Type</th>
                        <th style={{ width: 35 }}>Elapsed</th>
                        <th style={{ width: 125 }}>Name</th>
                        <th style={{ width: 100 }}>Ruleset</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        return (
                            <tr key={row.key} className={getRowClassName(index)} onClick={rowClickHandler}>
                                <td data-eventkey={row.key} data-column="sequence" title={`${row.sequenceDisplay}`} >{row.sequenceDisplay}</td>
                                <td data-eventkey={row.key} data-column="threadName" title={`${row.threadName}`}>{row.threadName}</td>
                                <td data-eventkey={row.key} data-column="interaction" title={`${row.interaction}`}>{row.interaction}</td>
                                <td data-eventkey={row.key} data-column="activityNumber" title={`${row.activityNumber}`}>{row.activityNumber}</td>
                                <td data-eventkey={row.key} data-column="stepMethod" title={`${row.stepMethodDisplay}`}>{row.stepMethodDisplay}</td>
                                <td data-eventkey={row.key} data-column="primaryPageName" title={`${row.primaryPageNameDisplay}`}>{row.primaryPageNameDisplay}</td>
                                <td data-eventkey={row.key} data-column="stepNumber" className={styles.stepNumber} title={`${row.stepNumber}`}>{row.stepNumber}</td>
                                <td data-eventkey={row.key} data-column="status" title={`${row.statusDisplay}`}>{row.statusDisplay}</td>
                                <td data-eventkey={row.key} data-column="eventName" className={row.eventNameDisplay === 'Access Denied' ? styles.accessDenied : ''} title={`${row.eventNameDisplay}`}>{row.eventNameDisplay}</td>
                                <td data-eventkey={row.key} data-column="elapsed" title={`${row.elapsedDisplay}`}>{row.elapsedDisplay}</td>
                                <td data-eventkey={row.key} data-column="name" title={`${row.nameDisplay}`}>{row.nameDisplay}</td>
                                <td data-eventkey={row.key} data-column="ruleset" title={`${row.rulesetDisplay}`}>{row.rulesetDisplay}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
