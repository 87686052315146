import TraceDataUploader from './TraceDataUploader';
import TraceDataViewer from './TraceDataViewer';
import styles from './Main.module.css'
import TraceLogDataAccess from '../tracer-data/TraceLogDataAccess';

interface MainProps {
    traceLogDataAccessor: TraceLogDataAccess
    dataLoaded: boolean
    setDataLoaded: (b: boolean) => void
}

export default function Main(props: MainProps) {
    return (
        <main className={styles.main}>
            {props.dataLoaded ? (
                <TraceDataViewer traceLogDataAccessor={props.traceLogDataAccessor} />
            ) : (
                <TraceDataUploader setDataLoadedState={props.setDataLoaded} />
            )}
        </main>
    )
}
