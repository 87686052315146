import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TraceLogDataAccess from './tracer-data/TraceLogDataAccess';
import TraceEventDetailView from './components/TraceEventDetailView';
import TraceEventPropertiesView from './components/TraceEventPropertiesView';
import TraceEventPageView from './components/TraceEventPageView';
import settingsManager, { applyTheme } from './models/SettingsManager';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

applyTheme(settingsManager.theme)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const traceLogDataAccessor = new TraceLogDataAccess()
const searchParams = new URLSearchParams(window.location.search)
const eventKey = searchParams.get('event')
const viewType = searchParams.get('viewType') || 'detail'
const pageName = searchParams.get('pageName') || undefined

if (eventKey === null) {
  // main tracer view
  traceLogDataAccessor.hasTraceLogs().then(hasData => {
    root.render(
      <React.StrictMode>
        <App initialDataLoaded={hasData} traceLogDataAccessor={traceLogDataAccessor} />
      </React.StrictMode>
    );
  })
} else {
  // event detail view
  traceLogDataAccessor.getTraceEvent(Number.parseInt(eventKey)).then(ev => {
    const renderChild = (viewType: string) => {
      switch (viewType) {
        case 'primary':
          return <TraceEventPageView pageName={ev.primaryPageName} pageContent={ev.primaryPageContent} />
        case 'namedPage':
          return <TraceEventPageView pageName={pageName} pageContent={ev.namedPages.get(pageName as string)} />
        case 'parameters':
          return <TraceEventPropertiesView pageName={ev.parameterPageName} properties={ev.parameterPageContent} />
        case 'variables':
          return <TraceEventPropertiesView pageName="LocalVariables" properties={ev.localVariables} />
        default:
          return <TraceEventDetailView event={ev} />
      }
    }

    root.render(
      <React.StrictMode>
        {renderChild(viewType)}
      </React.StrictMode>
    )
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

if (process.env.NODE_ENV !== 'production') {
  (async () => {
    const axe = (await import('@axe-core/react')).default
    axe(React, ReactDOM, 1000);
  })()
}

serviceWorkerRegistration.register()