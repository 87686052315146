import styles from "./Header.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBugs, faEraser, faGear } from '@fortawesome/sharp-regular-svg-icons'


interface HeaderProps {
  dataLoaded: boolean
  clearDataLoaded: () => void
  openSettings: () => void
}

function HeaderMenu(props: HeaderProps) {
  return (
    <menu className={styles.menu}>
      {props.dataLoaded && <li><button type="button" onClick={() => props.clearDataLoaded()}>
        <FontAwesomeIcon icon={faEraser} size="sm" /> Clear
      </button></li>}
      <li><button type="button" onClick={() => props.openSettings()}>
        <FontAwesomeIcon icon={faGear} size="sm" /> Settings
      </button></li>
    </menu>
  )
}

export default function Header(props: HeaderProps) {
  return (
    <header className={styles.header}>
      <h1>Pega Tracer</h1>
      <span className={styles.disclaimer}>
        <FontAwesomeIcon icon={faBugs} />&nbsp;
        alpha release — there will be bugs&nbsp;
        <FontAwesomeIcon icon={faBugs} flip="horizontal" />
      </span>
      <HeaderMenu dataLoaded={props.dataLoaded} clearDataLoaded={props.clearDataLoaded} openSettings={props.openSettings} />
    </header>
  )
}
