import { InputHTMLAttributes, useEffect, useMemo, useRef, useState } from "react"
import { Cell, Column, flexRender, getCoreRowModel, getSortedRowModel, Row, Table, useReactTable } from "@tanstack/react-table"
import { createColumnHelper, getFacetedUniqueValues, getFilteredRowModel } from "@tanstack/table-core"
import TraceEvent from "../tracer-data/TraceEvent"
import styles from './TraceDataTable.module.css'
import { useVirtualizer } from "@tanstack/react-virtual"
import { launchTraceEventDetailView } from "./TraceEventDetailView"
import { launchTraceEventPageView } from "./TraceEventPageView"
import TraceLogDataAccess from "../tracer-data/TraceLogDataAccess"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp } from '@fortawesome/sharp-regular-svg-icons'

const columnHelper = createColumnHelper<TraceEvent>()
const columns = [
    columnHelper.accessor('sequenceDisplay', {
        id: 'sequence',
        header: () => 'Line',
        size: 25,
        minSize: 25,
        enableSorting: true
    }),
    columnHelper.accessor('threadName', {
        header: () => 'Thread',
        size: 75,
        enableColumnFilter: true
    }),
    columnHelper.accessor('interaction', {
        header: () => 'Int',
        size: 15,
        minSize: 15,
    }),
    columnHelper.accessor('activityNumber', {
        header: () => 'Rule#',
        size: 25,
        minSize: 15
    }),
    columnHelper.accessor('stepMethodDisplay', {
        id: 'stepMethod',
        header: () => 'Step Method',
        size: 100,
    }),
    columnHelper.accessor('primaryPageNameDisplay', {
        id: 'primaryPageName',
        header: () => 'Step Page',
        size: 100,
        enableColumnFilter: true
    }),
    columnHelper.accessor('stepNumber', {
        id: 'step',
        header: () => 'Step',
        size: 20,
        minSize: 15
    }),
    columnHelper.accessor('statusDisplay', {
        id: 'status',
        header: () => 'Status',
        size: 75,
        enableColumnFilter: true
    }),
    columnHelper.accessor('eventNameDisplay', {
        id: 'eventName',
        header: () => 'Event Type',
        size: 75,
        enableColumnFilter: true
    }),
    columnHelper.accessor('elapsedDisplay', {
        id: 'elapsed',
        header: () => 'Elapsed',
        size: 35
    }),
    columnHelper.accessor('nameDisplay', {
        id: 'name',
        header: () => 'Name',
        size: 125,
        enableColumnFilter: true
    }),
    columnHelper.accessor('rulesetDisplay', {
        id: 'ruleset',
        header: () => 'Ruleset',
        size: 100,
        enableColumnFilter: true
    })
]

interface TraceDataTableProps {
    traceLogDataAccessor: TraceLogDataAccess
    traceLogKey: number
}

export default function TraceDataTable(props: TraceDataTableProps) {
    const [data, setData] = useState<TraceEvent[]>([])
    useEffect(() => {
        props.traceLogDataAccessor.getTraceEvents(props.traceLogKey).then(setData)
    }, [props.traceLogDataAccessor, props.traceLogKey])

    const table = useReactTable({
        data,
        columns,
        defaultColumn: {
            enableSorting: false,
            enableColumnFilter: false
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    const tableContainerRef = useRef<HTMLDivElement>(null)

    const { rows } = table.getRowModel()
    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableContainerRef.current,
        count: rows.length,
        estimateSize: () => 23,
        overscan: 5,
        //        keyExtractor: (i: number) => rows[i].original.key as number
    })

    const virtualRowTotal = rowVirtualizer.getTotalSize()
    const virtualRows = rowVirtualizer.getVirtualItems()
    const paddingTop =
        virtualRows.length > 0
            ? virtualRows?.[0]?.start || 0
            : 0
    const paddingBottom =
        virtualRows.length > 0
            ? virtualRowTotal - (virtualRows?.[virtualRows.length - 1]?.end || 0)
            : 0

    const getRowClassName = (row: Row<TraceEvent>) => {
        const classNames = []
        const event = row.original
        switch (event.eventType) {
            case 'Interaction':
            case 'Stream Rules':
                classNames.push(styles.interactionEvent)
                break
            case 'ADP Load':
                classNames.push(styles.adpEvent)
                break
        }

        classNames.push(row.index % 2 === 0 ? styles.odd : styles.even)

        return classNames.join(' ')
    }

    const getCellClassName = (cell: Cell<TraceEvent, any>) => {
        if (cell.column.id === 'step') {
            return styles.stepNumber
        }
        if (cell.column.id === 'eventName' && cell.getValue() === 'Access Denied') {
            return styles.accessDenied
        }
    }

    const cellClickHandler = (cell: Cell<TraceEvent, any>) => {
        if (cell.column.id === 'primaryPageName' && cell.row.original.primaryPageName) {
            // display primary page data
            launchTraceEventPageView('primary', cell.row.original.key)
        } else {
            launchTraceEventDetailView(cell.row.original.key)
        }
    }

    return (
        <div ref={tableContainerRef} className={styles.container}>
            <table>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                                    {header.isPlaceholder
                                        ? null
                                        : (
                                            <>
                                                <div
                                                    {...{
                                                        className: [styles.headerLabel, header.column.getCanSort() ? styles.sortableHeader : ''].join(' '),
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    &nbsp;
                                                    {{
                                                        asc: <FontAwesomeIcon icon={faSortUp} size="sm" />,
                                                        desc: <FontAwesomeIcon icon={faSortDown} size="sm" />,
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </div>
                                                {header.column.getCanFilter() ? (
                                                    <Filter column={header.column} table={table} />
                                                ) : null}
                                            </>
                                        )
                                    }
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {paddingTop > 0 && (
                        <tr>
                            <td style={{ height: `${paddingTop}px` }} />
                        </tr>
                    )}
                    {virtualRows.map(virtualRow => {
                        const row = rows[virtualRow.index]
                        return (
                            <tr key={row.id} className={getRowClassName(row)}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id} className={getCellClassName(cell)} title={cell.getValue() as any} onClick={() => cellClickHandler(cell)}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                    {paddingBottom > 0 && (
                        <tr>
                            <td style={{ height: `${paddingBottom}px` }}></td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    {table.getFooterGroups().map(footerGroup => (
                        <tr key={footerGroup.id}>
                            {footerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.footer,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </div>
    )
}

function Filter({ column, table }: { column: Column<any, unknown>, table: Table<any> }) {
    const columnFilterValue = column.getFilterValue()
    const uniqueValues = column.getFacetedUniqueValues()
    const sortedUniqueValues = useMemo(() => {
        return Array.from(uniqueValues.keys()).sort()
    }, [uniqueValues])

    return (
        <>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.slice(0, 5000).filter(value => !!value).map((value: any) =>
                    <option value={value} key={value} />
                )}
            </datalist>
            <FilterInput type="text" value={(columnFilterValue ?? '') as string}
                onChange={value => column.setFilterValue(value)}
                placeholder={`Search… (${column.getFacetedUniqueValues().size})`}
                className=""
                list={column.id + 'list'}
            />
        </>
    )
}

function FilterInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number,
    onChange: (value: string | number) => void,
    debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value, debounce, onChange])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}