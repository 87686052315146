import { useEffect, useRef, useState } from "react"
import TraceLogDataAccess from "../tracer-data/TraceLogDataAccess"
import TraceEvent from "../tracer-data/TraceEvent"
import { flamegraph, StackFrame } from "d3-flame-graph"
import { select } from "d3"
import styles from "./TraceDataFlameGraph.module.css"

function parseTraceEvent(events: TraceEvent[]): StackFrame {

    let children: StackFrame[] = []
    const root = {
        name: "Trace Log",
        value: 1,
        children: children
    }
    const stack: StackFrame[] = [root]

    events.reverse().forEach((event, index) => {
        if (event.eventType === 'Stream Rules') {
            switch (event.stepMethod) {
                case 'Stream Begin':
                    const frame = {
                        name: `Stream ${event.nameDisplay}`,
                        value: event.elapsed || 1,
                        children: []
                    }
                    children.push(frame)
                    stack.push(frame)
                    children = frame.children
                    break
                case 'Stream End':
                    stack.pop()
                    children = stack[stack.length - 1].children
                    break
            }
        } else if (event.eventType === 'DB Query') {
            const name = `${event.eventType} ${event.eventNameDisplay}`
            children.push({
                name: name,
                value: event.elapsed || 1,
                children: []
            })
        } else {
            let frame: StackFrame
            switch (event.eventName) {
                case 'Interaction Begin':
                    frame = {
                        name: `Interaction ${event.nameDisplay}`,
                        value: event.elapsed || 1,
                        children: []
                    }
                    children.push(frame)
                    stack.push(frame)
                    children = frame.children
                    break
                case 'Step Begin':
                    frame = {
                        name: `Step ${event.stepNumber} ${event.stepMethod}`,
                        value: event.elapsed || 1,
                        children: []
                    }
                    children.push(frame)
                    stack.push(frame)
                    children = frame.children
                    break
                case 'Activity Begin':
                    let name = `Activity ${event.nameDisplay}`
                    if (event.activityName === 'ReloadSection') {
                        let streamName = event.parameterPageContent?.get("StreamName")
                        if (!streamName) {
                            streamName = event.parameterPageContent?.get("StreamList")
                            if (streamName) {
                                streamName = streamName.substring(0, streamName.indexOf('|'))
                            }
                        }
                        if (streamName) {
                            name = `Activity ReloadSection[${streamName}]`
                        }
                    }
                    frame = {
                        name: name,
                        value: event.elapsed || 1,
                        children: []
                    }
                    children.push(frame)
                    stack.push(frame)
                    children = frame.children
                    break
                case 'When Begin':
                    frame = {
                        name: `When ${event.nameDisplay}`,
                        value: event.elapsed || 1,
                        children: []
                    }
                    children.push(frame)
                    stack.push(frame)
                    children = frame.children
                    break
                case 'Interaction End':
                case 'Step End':
                case 'Activity End':
                case 'When End':
                    stack.pop()
                    children = stack[stack.length - 1].children
                    break
                case 'Access Denied':
                    break
                default:
                    children.push({
                        name: `${event.eventType} ${event.nameDisplay}`,
                        value: event.elapsed || 1,
                        children: []
                    })
            }
        }
    })
    return root
}

function debounce(task: Function, delay: number) {
    console.log('debounce ' + delay)
    let innerTask: { promise: Promise<any> | null, cancel: (reason?: string) => void } = {
        promise: null,
        cancel: () => void 0
    }

    return async (...args: any) => {
        innerTask.cancel()
        let cancel: (reason?: string) => void = () => void 0
        let promise = new Promise((resolve, reject) => {
            cancel = reject
            setTimeout(resolve, delay)
        })
        innerTask = {
            promise,
            cancel
        }

        try {
            await innerTask.promise
        }
        catch (_) {
            // rejected promise
        }

        await task(...args)
    }
}

interface TraceDataFlameGraphProps {
    traceLogDataAccessor: TraceLogDataAccess
    traceLogKey: number
}

export default function TraceDataFlameGraph(props: TraceDataFlameGraphProps) {
    const ref = useRef<HTMLDivElement>(null)
    const [data, setData] = useState<any>()
    useEffect(() => {
        (async () => {
            const newData = await props.traceLogDataAccessor.getTraceEvents(props.traceLogKey)
            setData(parseTraceEvent(newData))
        })()
    }, [props.traceLogDataAccessor, props.traceLogKey])

    useEffect(() => {
        if (ref.current && data) {
            ref.current.innerHTML = ''
            const flame = flamegraph()
                .setColorHue('cold')
                // .transitionEase("easeCubic")
                .selfValue(true)
                .width(ref.current.clientWidth)
                .cellHeight(25)
            select(ref.current).datum(data).call(flame)

            const resizer = debounce(() => {
                if (ref.current) {
                    flame.width(ref.current.clientWidth).update(data)
                }
            }, 500)
            window.addEventListener('resize', resizer)
            return () => {
                window.removeEventListener('resize', resizer)
                flame.destroy()
            }
        }
    }, [ref, data])

    return (
        <div className={styles.container} ref={ref}>Flame Graph Loading</div>
    )
}