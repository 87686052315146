import { launchPopup } from "./TraceEventDetailView"
import styles from "./TraceEventPropertiesView.module.css"

interface TraceEventPropertiesViewProps {
    pageName?: string
    properties?: Map<string, string>
}

export default function TraceEventPropertiesView(props: TraceEventPropertiesViewProps) {
    const propertyRows = []
    for (let [key, value] of props.properties?.entries() || []) {
        propertyRows.push(
            <tr key={key}>
                <th>{key}</th>
                <td>{value}</td>
            </tr>
        )
    }

    return <div className={styles.eventDetail}>
        <header><h1>Properties on Page TraceEvent [{props.pageName}]</h1></header>
        <main>
            <div>
                <h2>Properties on Page {props.pageName}</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {propertyRows}
                    </tbody>
                </table>
            </div>
        </main>
    </div>
}

export function traceEventPropertiesViewLink(viewType: 'parameters' | 'variables', eventKey: number | undefined) {
    return process.env.PUBLIC_URL + `/index.html?event=${eventKey}&viewType=${viewType}`
}

export function launchTraceEventPropertiesView(viewType: 'parameters' | 'variables', eventKey: number | undefined) {
    launchPopup(traceEventPropertiesViewLink(viewType, eventKey))
}
