import { useEffect, useRef, useState } from "react"
import styles from './SettingsModal.module.css'
import settingsManager, { ThemeSetting } from "../models/SettingsManager"

interface SettingsModalProps {
    isOpen: boolean
    closeSettings: () => void
}

export default function SettingsModal(props: SettingsModalProps) {
    const dialogRef = useRef<HTMLDialogElement>(null)

    useEffect(() => {
        if (dialogRef.current) {
            const dialog = dialogRef.current
            if (props.isOpen) {
                if (!dialog.open) {
                    (dialog as any).showModal()
                }
            } else {
                if (dialog.open) {
                    (dialog as any).close()
                }
            }
        }
    }, [dialogRef, props.isOpen])

    const [themeSetting, setThemeSetting] = useState(settingsManager.theme)

    const changeTheme = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
        const newTheme = ThemeSetting[changeEvent.target.value as keyof typeof ThemeSetting]
        setThemeSetting(newTheme)
    }

    const save = () => {
        settingsManager.theme = themeSetting
        props.closeSettings()
    }

    const cancel = () => {
        setThemeSetting(settingsManager.theme)
        props.closeSettings()
    }

    const dialogClasses = [styles.modal]
    if (themeSetting === ThemeSetting.Dark) {
        dialogClasses.push('dark')
    }
    if (themeSetting === ThemeSetting.Light) {
        dialogClasses.push('light')
    }

    return (
        <dialog id="settingsDialog" className={dialogClasses.join(' ')} ref={dialogRef} onClose={cancel}>
            <form method="dialog">
                <header><h1>Settings</h1></header>
                <main>
                    <div>
                        <label htmlFor="themeSetting">Theme Setting: </label>
                        <select name="theme" id="themeSetting" onChange={changeTheme} value={themeSetting} >
                            <option value={ThemeSetting.System} >System</option>
                            <option value={ThemeSetting.Dark} >Dark</option>
                            <option value={ThemeSetting.Light} >Light</option>
                        </select>
                    </div>
                </main>
                <footer>
                    <button type="button" onClick={cancel}>Cancel</button>
                    <button type="button" onClick={save} >Save</button>
                </footer>
            </form>
        </dialog>
    )
}
