import TraceEvent from "./TraceEvent";
import TraceLog, { TraceLogStatus } from "./TraceLog";

const DATABASE_NAME = 'pega-tracer-database'
const DATABASE_VERSION = 1

const TRACE_LOG_STORE = 'tracelog'
const TRACE_EVENT_STORE = 'traceevent'
const TRACE_EVENT_INDEX = 'traceevent-tracelog'

class TraceLogImportTransaction {
  private readonly db: IDBDatabase
  private log?: TraceLog

  constructor(db: IDBDatabase) {
    this.db = db
  }

  async importTraceLog(name: string, timestamp: Date): Promise<Number> {
    const transaction = this.db.transaction([TRACE_LOG_STORE], 'readwrite')
    const os = transaction.objectStore(TRACE_LOG_STORE)

    const tracelog = new TraceLog(name, timestamp, TraceLogStatus.Importing)
    const req = os.add(tracelog.serialize())
    return new Promise<number>((resolve, reject) => {
      req.onerror = ev => {
        ev.stopPropagation()
        reject(new Error(`error storing tracelog: ${req.error}`))
      }
      req.onsuccess = _ev => {
        const key = req.result as number
        console.log(`tracelog ${key} added`)
        this.log = tracelog.withKey(key)
        resolve(key)
      }
    })
  }

  async importTraceEvents(events: TraceEvent[]): Promise<void> {
    const transaction = this.db.transaction([TRACE_EVENT_STORE], 'readwrite')
    const os = transaction.objectStore(TRACE_EVENT_STORE)

    const reqs: Promise<void>[] = []
    for (let event of events) {
      reqs.push(new Promise<void>((resolve, reject) => {
        const req = os.add(event.serialize())
        const reqid = reqs.length + 1
        req.onerror = ev => {
          ev.stopPropagation()
          reject(new Error(`error storing traceevent ${reqid}: ${req.error}`))
        }
        req.onsuccess = _ev => {
          resolve()
        }
      }))
    }

    console.log(`${reqs.length} trace events queued`)
    await Promise.all(reqs)
    console.log(`${reqs.length} trace events added`)
  }

  async close() {
    if (this.log) {
      const transaction = this.db.transaction([TRACE_LOG_STORE], 'readwrite')
      const os = transaction.objectStore(TRACE_LOG_STORE)
      this.db.close()
      const req = os.put(this.log.withStatus(TraceLogStatus.Completed).serialize())
      await new Promise<void>((resolve, reject) => {
        req.onerror = ev => {
          ev.stopPropagation()
          reject(new Error(`error completing tracelog import: ${req.error}`))
        }
        req.onsuccess = _ev => {
          resolve()
        }
      })
    }
  }
}

export default class TraceLogDataAccess {
  private async openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DATABASE_NAME, DATABASE_VERSION)

      request.onerror = (ev) => {
        reject(ev)
      }

      request.onsuccess = (ev) => {
        const db: IDBDatabase = (ev.target as any).result
        db.onerror = ev => {
          console.error('unhandled error bubbled to open database')
          console.error(ev)
        }
        resolve(db)
      }

      request.onupgradeneeded = (ev) => {
        const db = (ev.target as any).result as IDBDatabase
        if (ev.oldVersion < 1) {
          // create first version objectstore
          let os = db.createObjectStore(TRACE_LOG_STORE, {
            keyPath: 'key',
            autoIncrement: true,
          })

          os = db.createObjectStore(TRACE_EVENT_STORE, {
            keyPath: 'key',
            autoIncrement: true,
          })
          os.createIndex(TRACE_EVENT_INDEX, 'traceLogKey', { unique: false })
        }
      }
    })
  }

  async hasTraceLogs(): Promise<boolean> {
    const db = await this.openDB()
    const txn = db.transaction(TRACE_LOG_STORE, 'readonly')
    const os = txn.objectStore(TRACE_LOG_STORE)
    const req = os.count()
    db.close()

    return new Promise<boolean>((resolve, reject) => {
      req.onerror = ev => {
        reject((ev.target as IDBRequest).error)
      }
      req.onsuccess = _ev => {
        resolve(req.result > 0)
      }
    })
  }

  async createImportTransaction(): Promise<TraceLogImportTransaction> {
    const db = await this.openDB()
    return new TraceLogImportTransaction(db)
  }

  async clearAll() {
    const db = await this.openDB()
    const transaction = db.transaction([TRACE_LOG_STORE, TRACE_EVENT_STORE], 'readwrite')

    const result = new Promise<void>((resolve, reject) => {
      transaction.onerror = ev => {
        ev.stopPropagation()
        if ((ev.target as IDBRequest)?.error) {
          reject((ev.target as IDBRequest).error)
        } else {
          reject(ev)
        }
      }
      transaction.oncomplete = _ev => {
        console.log('clear all trace log data transaction completed')
        resolve()
      }
    })

    await this.clearObjectStore(transaction, TRACE_EVENT_STORE)
    await this.clearObjectStore(transaction, TRACE_LOG_STORE)

    db.close()
    return result
  }

  private async clearObjectStore(txn: IDBTransaction, objectStoreName: string) {
    const os = txn.objectStore(objectStoreName)
    const req = os.clear()
    return new Promise<void>((resolve, reject) => {
      req.onerror = ev => {
        ev.stopPropagation()
        reject(new Error(`error clearing ${objectStoreName}: ${req.error}`))
      }
      req.onsuccess = _ev => {
        resolve()
      }
    })
  }

  async getTraceLogs(): Promise<TraceLog[]> {
    const data: TraceLog[] = []

    const db = await this.openDB()
    const transaction = db.transaction([TRACE_LOG_STORE], 'readonly')
    const os = transaction.objectStore(TRACE_LOG_STORE)

    const cursor = os.openCursor(undefined)
    db.close()

    return new Promise((resolve, reject) => {
      cursor.onerror = (ev) => {
        ev.stopPropagation()
        reject(new Error(`error retrieving tracelog list ${cursor.error}`))
      }
      cursor.onsuccess = (ev) => {
        const result = cursor.result
        if (result === null) {
          console.log(`resolve ${data.length}`)
          resolve(data)
          return
        }

        data.push(TraceLog.deserialize(result.value))
        result.continue()
      }
    })
  }

  async getTraceEvents(tracelogkey: number): Promise<TraceEvent[]> {
    const data: TraceEvent[] = []

    const db = await this.openDB()
    const transaction = db.transaction([TRACE_EVENT_STORE], 'readonly')
    const os = transaction.objectStore(TRACE_EVENT_STORE)
    const index = os.index(TRACE_EVENT_INDEX)

    const cursor = index.openCursor(IDBKeyRange.only(tracelogkey), 'prev')
    db.close()

    return new Promise((resolve, reject) => {
      cursor.onerror = (ev) => {
        ev.stopPropagation()
        reject(new Error(`error retrieving events for a tracelog ${cursor.error}`))
      }
      cursor.onsuccess = (ev) => {
        const result = cursor.result
        if (result === null) {
          console.log(`resolve ${data.length}`)
          resolve(data)
          return
        }

        data.push(Object.assign(new TraceEvent(), result.value))
        result.continue()
      }
    })
  }

  async getTraceEvent(traceeventkey: IDBValidKey): Promise<TraceEvent> {
    const db = await this.openDB()
    const transaction = db.transaction([TRACE_EVENT_STORE], 'readonly')
    const os = transaction.objectStore(TRACE_EVENT_STORE)
    const request = os.get(traceeventkey)
    db.close()

    return new Promise((resolve, reject) => {
      request.onsuccess = _ => {
        resolve(Object.assign(new TraceEvent(), request.result))
      }
      request.onerror = ev => {
        ev.stopPropagation()
        reject(new Error(`error retrieving trace event ${traceeventkey}`))
      }
    })
  }
}
