import TraceEvent from "./TraceEvent"

export enum TraceLogStatus {
  New, Importing, Completed, Active
}

export default class TraceLog {
  #key?: number
  #name: string
  #timestamp: Date
  #status: TraceLogStatus
  #events: TraceEvent[] = []

  constructor(name: string, timestamp: Date, status?: TraceLogStatus, key?: number) {
    this.#name = name
    this.#timestamp = timestamp
    this.#status = status || TraceLogStatus.New

    if (typeof key === 'number') {
      this.#key = key
    }
  }

  get key() {
    return this.#key
  }

  get name() {
    return this.#name
  }

  get timestamp() {
    return this.#timestamp
  }

  get status() {
    return this.#status
  }

  get events(): Iterable<TraceEvent> {
    return this.#events
  }

  withStatus(newStatus: TraceLogStatus): any {
    return new TraceLog(this.#name, this.#timestamp, newStatus, this.#key)
  }

  withKey(newKey: number): TraceLog {
    return new TraceLog(this.#name, this.#timestamp, this.#status, newKey)
  }

  public serialize(): any {
    const obj: any = {
      name: this.#name,
      timestamp: this.#timestamp,
      status: this.#status,
    }
    if (this.#key) {
      obj.key = this.#key
    }
    return obj
  }

  static deserialize(obj: any): TraceLog {
    return new TraceLog(obj.name, obj.timestamp, obj.status, obj.key)
  }
}
